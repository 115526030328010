import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import PictureList from "../components/picture-list"

const PicturesPage = ({ data }) => {

    const pictures = data.allPicturesJson.edges.map(({ node }) => node)

    return (
        <>
            <Seo title="Pictures"/>
            <h1>Pictures</h1>
            <p>
                This page contains pictures of me taken during talks and interviews.
                Click on any picture to enlarge.
            </p>

            <section>
                <PictureList pictures={pictures}
                             displayText={true}
                             displaySource={true}
                             square={true}/>
            </section>
        </>
    )
}

export default PicturesPage

export const query = graphql`
    query {
        allPicturesJson(sort: { fields: [priority], order: [DESC] }) {
            edges {
                node {
                    id
                    ...PictureFragment
                }
            }
        }
    }
`
